import React, { useEffect, useState } from 'react';
import './AdminRoles.css';
import Instance from '../../AxiosConfig';
import { Select, Switch } from 'antd';

const onChange = async (checked, roleName, name, setRoleDetails) => {
    const roleAccess = checked;
    const data = {
        roleName,
        name,
        roleAccess,
    };

    try {
        await Instance.post("/api/v1/roleAccess/create", data, {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem("adminAuthToken")}`,
                "Content-Type": "application/json",
            },
        });
        console.log(`${name} access for ${roleName} is now ${roleAccess ? 'enabled' : 'disabled'}`);
        // Update the local state immediately after a successful API call
        setRoleDetails(prevDetails => ({
            ...prevDetails,
            [name]: roleAccess
        }));
    } catch (error) {
        console.error(`Error updating role access for ${name}:`, error);
    }
};

const AdminRoles = () => {
    const [roleOptions, setRoleOptions] = useState([]);
    const [selectedRole, setSelectedRole] = useState('');
    const [roleDetails, setRoleDetails] = useState({});

    useEffect(() => {
        const fetchRoles = async () => {
            try {
                const response = await Instance.get("api/v1/roleAccess/roles", {
                    headers: {
                        Authorization: `Bearer ${sessionStorage.getItem("adminAuthToken")}`,
                        "Content-Type": "application/json",
                    },
                });
                console.log("Roles: ", response.data);

                const filteredRoles = response.data.filter(role => role.roleName !== 'ROLE_NORMAL');

                const roles = filteredRoles.map(role => ({
                    value: role.roleName.replace(/_/g, ' '),
                    label: role.roleName.replace(/_/g, ' ')
                }));
                console.log("filtered role:", roles);
                setRoleOptions(roles);
                if (roles.length > 0) {
                    setSelectedRole(roles[0].value);
                }
            } catch (error) {
                console.error("Error fetching roles:", error);
            }
        };

        fetchRoles();
    }, []);


    const fetchRoleDetails = async (roleName) => {
        try {
            const response = await Instance.get(`api/v1/roleAccess/${roleName}`, {
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem("adminAuthToken")}`,
                    "Content-Type": "application/json",
                },
            });
            console.log("Role details: ", response.data);

            const details = response.data.reduce((acc, curr) => {
                acc[curr.name] = curr.roleAccess;
                return acc;
            }, {});
            console.log("fetched details is:", details)

            setRoleDetails(details);
        } catch (error) {
            console.error("Error fetching role details:", error);
        }
    };

    useEffect(() => {
        if (selectedRole) {
            fetchRoleDetails(selectedRole);
        }
    }, [selectedRole]);

    const roles = [
        { title: 'Dashboard Access', description: 'Grants full access to monitor key metrics.' },
        { title: 'Manage Orders', description: 'Provides the capability to view, edit, and process orders from customers.' },
        { title: 'Manage Users', description: 'Oversee accounts and roles.' },
        { title: 'Manage Users Details', description: 'View and edit user details, including personal info, contact data, and activity logs.' },
        { title: 'Manage Coupons', description: 'Grants access to create, modify, and track promotional coupons and discount codes.' },
        { title: 'Product Categories Access', description: 'Enables management of product categories including adding, updating, and deleting categories.' },
        { title: 'Access to View All Products', description: 'Displays a list of all available products with key details.' },
        { title: 'Transaction Management', description: 'Provides access to view and manage financial transactions and reports.' },
        { title: 'Manage Brands', description: 'Allows the admin to add, update, and oversee brand details and branding strategies.' },
        { title: 'Add Products', description: 'Add new products with images and details to the inventory.' },
        { title: 'Product List Access', description: 'Enables viewing and managing the complete list of products available in the system.' },
        { title: 'Product Detail Access', description: 'View product details, including specifications, pricing, and inventory.' },
        { title: 'Access to Manage Admins', description: 'Allows the admin to view, add, and modify other admin accounts and their permissions.' },
        { title: 'Access to Admin Roles', description: 'Grants the ability to configure and assign different roles and permissions to other admins.' },
        { title: 'Access to Store Information', description: 'Allows viewing and managing store details like address, contact info, and hours.' },
        { title: 'Store Details Management', description: 'Enables viewing and managing store name, location, type, and documentation.' },
        { title: 'Access to Configure Settings', description: 'Grants permission to access and modify system settings and configurations.' },
        { title: 'Access to Review', description: 'Provides the capability to view and manage customer reviews and feedback.' },
        { title: 'Access to Feedback', description: 'Collect and analyze customer feedback to improve service and satisfaction.' },
        { title: 'Access to FAQ', description: 'Grants the ability to manage and update the Frequently Asked Questions (FAQ) section.' },
        { title: 'Access to Contact', description: 'Grants the ability to manage and reply the enquiries received through contact us.' }
    ];

    return (
        <div className='container-fluid container adminroles-container'>
            <div className="main-title-all">
                <div style={{ display: "flex", justifyContent: "start", marginBottom: "30px" }}>
                    <span>Admin Roles</span>
                </div>
            </div>
            <div className='d-flex justify-content-between'>
                <h5 className='add_product_heading'>Admin Roles Management</h5>
                <Select
                    options={roleOptions}
                    value={selectedRole}
                    onChange={(value) => setSelectedRole(value)}
                    placeholder="Select a role"
                    style={{
                        width: '150px',
                    }}
                />
            </div>
            <h5 className='customizable_heading'>Configure {selectedRole.charAt(0).toUpperCase() + selectedRole.slice(1).toLowerCase()} Access Rights:</h5>
            <div className='admin_role_list'>
                {roles.map(role => (
                    <div className='admin_role_list_p' key={role.title}>
                        <div>
                            <p className='admin_role_title'>
                                <strong>{role.title}:</strong>
                            </p>
                            <p className='admin_role_para'>
                                {role.description}
                            </p>
                        </div>
                        <Switch
                            checked={roleDetails[role.title] || false}
                            onChange={checked => onChange(checked, selectedRole, role.title, setRoleDetails)}
                            disabled={!selectedRole}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default AdminRoles;
