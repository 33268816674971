import React, { useState, useEffect } from "react";
import { Table, Pagination, Select } from "antd";
import { IoFilter } from "react-icons/io5";
import { IoIosArrowDown } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import Instance from "../../AxiosConfig";
import AddProducts from "./AddProducts";
import "./Product.css";
import jsPDF from "jspdf";
import InvoiceLogo1 from "../../Assets/images/white-bg-logo.png";
import icon1 from "../../Assets/images/user-icon-1.png";
import icon2 from "../../Assets/images/order-icon-1.png";
import icon3 from "../../Assets/images/sales-icon-1.png";
import icon4 from "../../Assets/images/rupees-icon-1.png";
import { useDispatch, useSelector } from 'react-redux';

const { Option } = Select;

const bulkActions = [
  { value: "In stock", label: "In Stock" },
  { value: "Low stock", label: "Low Stocks" },
  { value: "Out of stock", label: "Out of Stocks" },
];

export const ProductList = ({ searchText, selectedDate, record }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [stats, setStats] = useState({
    totalCategories: 0,
    totalProducts: 0,
    totalBuyingPrice: 0,
    countTotalOrders: 0,
    sumTotalOrderCost: 0,
    inStockCount: 0,
    lowStockCount: 0,
    outofStockCount: 0,
  });
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 5,
    total: 0,
  });
  const [selectedBulkActions, setSelectedBulkActions] = useState([]);
  const products = useSelector((state) => state.products.products);
  const dispatch = useDispatch();
  console.log("FETCHED DATA", products)
  useEffect(() => {
    if (products.length === 0) {
      fetchProductData();
    } else {
      handleFilterAndPagination();
    }
  }, [products]);

  const navigate = useNavigate();

  const drawBorder = (doc) => {
    const pageWidth = doc.internal.pageSize.width;
    const pageHeight = doc.internal.pageSize.height;
    const borderMargin = 7;
    // Optionally set the line color or width
    doc.setLineWidth(0.3);
    doc.setDrawColor(0);
    doc.rect(borderMargin, borderMargin, pageWidth - 2 * borderMargin, pageHeight - 2 * borderMargin);
  };

  const handleExportRows = () => {
    const doc = new jsPDF();

    const tableData = filteredData.map((item, index) => [
      index + 1,
      item.productName,
      `${item.buyingPrice}`,
      item.quantity,
      item.remaining,
      item.receivedDate,
      item.availability,
    ]);

    const tableHeaders = [
      "SL.No",
      "PRODUCTS",
      "BUYING PRICE",
      "QUANTITY",
      "REMAINING",
      "RECEIVED DATE",
      "AVAILABILITY",
    ];

    doc.setFillColor("#fff");
    doc.rect(0, 0, doc.internal.pageSize.width, 40, "F");

    const logoImage = new Image();
    logoImage.src = InvoiceLogo1;

    logoImage.onload = function () {
      doc.addImage(logoImage, "PNG", 15, 10, 50, 20);
      doc.setFontSize(19);
      doc.setTextColor(80);

      const labely = 12;
      const baseY = 22;
      // const lineHeight = 7;

      const labelX = 90;
      const valueX = 165;
      const currentDate = new Date();
      const formattedDateTime = `${currentDate.toLocaleDateString()} ${currentDate.toLocaleTimeString()}`;

      doc.text(`PRODUCT LIST`, labelX, baseY);
      doc.setFontSize(11);
      doc.text(formattedDateTime, valueX, labely);

      const pageWidth = doc.internal.pageSize.width;
      const pageHeight = doc.internal.pageSize.height;

      doc.autoTable({
        head: [tableHeaders],
        body: tableData,
        startY: 40,
        headStyles: {
          fillColor: [27, 58, 87],
          textColor: [255, 255, 255],
        },
        styles: {
          cellPadding: 4,
          minCellHeight: 8,
          lineWidth: 0.2,
          lineColor: [200, 200, 200],
        },
        columnStyles: {
          0: { cellWidth: 15 }, // Adjust width for Serial Number column
        },
      });

      const totalPages = doc.getNumberOfPages();
      for (let i = 1; i <= totalPages; i++) {
        doc.setPage(i);
        doc.setFontSize(10);
        doc.text(`Page ${i} of ${totalPages}`, pageWidth - 20, pageHeight - 1, {
          align: "right",
        });
        drawBorder(doc);
      }

      const pdfBase64 = doc.output("datauristring");
      const newWindow = window.open();
      newWindow.document.write(
        `<iframe width="100%" height="100%" src="${pdfBase64}"></iframe>`
      );
    };
  };

  const handleOpenAddPostModal = () => {
    setIsModalVisible(true);
  };

  useEffect(() => {
    fetchProductData();
  }, []);

  useEffect(() => {
    setPagination((prev) => ({ ...prev, current: 1 }));
  }, [searchText, selectedDate, selectedBulkActions]);

  useEffect(() => {
    handleFilterAndPagination();
  }, [
    pagination.current,
    pagination.pageSize,
    searchText,
    selectedDate,
    selectedBulkActions,
    products,
  ]);
  const fetchProductData = async () => {
    setLoading(true);
    try {
      const url = "/api/v1/admin/product/";
      const response = await Instance.get(url);
      console.log("API Response:", response.data);

      if (response.status === 200) {
        const products = response.data;
        setData(products);

        if (products.length > 0) {
          const stats = products[0];
          console.log("Stats:", stats);
          setStats({
            totalCategories: stats.totalCategories || 0,
            totalProducts: stats.totalProducts || 0,
            totalBuyingPrice: stats.totalBuyingPrice || 0,
            countTotalOrders: stats.countTotalOrders || 0,
            sumTotalOrderCost: stats.sumTotalOrderCost || 0,
            inStockCount: stats.inStockCount || 0,
            lowStockCount: stats.lowStockCount || 0,
            outofStockCount: stats.outOfStockCount || 0,
          });
        } else {
          console.warn("No products found.");
        }
        console.log("Fetched Products:", products);
      }
    } catch (error) {
      console.error("Failed to fetch data:", error.response);
    }
    setLoading(false);
  };

  const handleFilterAndPagination = () => {
    let filtered = products;
    console.log("Selected Bulk Actions:", selectedBulkActions);

    if (searchText) {
      filtered = filtered.filter((product) =>
        product.productName.toLowerCase().includes(searchText.toLowerCase())
      );
    }

    if (selectedDate) {
      filtered = filtered.filter(
        (product) =>
          new Date(product.createdDate).toLocaleDateString() ===
          new Date(selectedDate).toLocaleDateString()
      );
    }

    if (selectedBulkActions.length > 0) {
      const selectedStatuses = selectedBulkActions;
      filtered = filtered.filter((product) =>
        selectedStatuses.includes(product.availability)
      );
    }

    setFilteredData(filtered);

    setPagination((prev) => ({
      ...prev,
      total: filtered.length,
    }));
  };

  const handleRowClick = (productListId) => {
    navigate(`/product-details/${productListId}`);
  };

  const handleBulkActionsChange = (value) => {
    setSelectedBulkActions(value);
  };

  const columns = [
    {
      title: "PRODUCTS",
      dataIndex: "productName",
      render: (text) => `${text}`,
    },
    {
      title: "BUYING PRICE",
      dataIndex: "buyingPrice",
    },
    {
      title: "QUANTITY",
      dataIndex: "quantity",
    },
    {
      title: "REMAINING",
      dataIndex: "remaining",
    },
    {
      title: "RECEIVED DATE",
      dataIndex: "receivedDate",
    },
    {
      title: "AVAILABILITY",
      dataIndex: "availability",
      render: (availability) => {
        let color = "";
        if (availability === "In stock") {
          color = "#28C76F";
        } else if (availability === "Low stock") {
          color = "#FFC600";
        } else if (availability === "Out of stock") {
          color = "#EA5455";
        }
        return <span style={{ color }}>{availability}</span>;
      },
    },
  ];

  const handleTableChange = (page, pageSize) => {
    setPagination((prev) => ({
      ...prev,
      current: page,
      pageSize,
    }));
  };

  const handlePageSizeChange = (current, size) => {
    setPagination((prev) => ({
      ...prev,
      current,
      pageSize: size,
    }));
  };

  const paginatedData = Array.isArray(filteredData)
    ? filteredData.slice(
      (pagination.current - 1) * pagination.pageSize,
      pagination.current * pagination.pageSize
    )
    : [];

  return (
    <div className="container">
      <div>
        <div className="justify-content-start d-flex">
          <h5>Overall Product List</h5>
        </div>
        <div
          className="d-flex products-detail-information mb-4"
        >
          <div className="productlist-information-details">
            <div className="account-card d-flex gap-2 p-3">
              <div>
                <h4 className="pb-4">Categories</h4>
                <h5 className="product-list-slider">{stats.totalCategories}</h5>
                <p className="product-list-slider-text" style={{ textAlign: "left" }}>Count</p>
              </div>
              <div>
                <img
                  src={icon1}
                  className="icon-1-img"
                  alt=""
                  style={{ width: "40px", height: "40px" }}
                />
              </div>
            </div>
          </div>

          <div className="productlist-information-details">
            <div className="account-card d-flex gap-2 p-3">
              <div>
                <h4 className="pb-4">Total Products</h4>
                <div className="d-flex gap-5">
                  <div style={{ textAlign: "left" }}>
                    <h5 className="product-list-slider">{stats.totalProducts}</h5>
                    <p className="product-list-slider-text">Count</p>
                  </div>
                  <div style={{ textAlign: "left" }}>
                    <h5 className="product-list-slider">₹ {stats.totalBuyingPrice}</h5>
                    <p className="product-list-slider-text">Revenue</p>
                  </div>
                </div>
              </div>
              <div>
                <img
                  src={icon2}
                  className="icon-1-img"
                  alt=""
                  style={{ width: "40px", height: "40px" }}
                />
              </div>
            </div>
          </div>

          <div className="productlist-information-details">
            <div className="account-card d-flex gap-2 p-3">
              <div>
                <h4 className="pb-4">Total Selling</h4>
                <div className="d-flex gap-4">
                  <div style={{ textAlign: "left" }}>
                    <h5 className="product-list-slider">{stats.countTotalOrders}</h5>
                    <p className="product-list-slider-text">Ordered Count</p>
                  </div>
                  <div style={{ textAlign: "left" }}>
                    <h5 className="product-list-slider">₹ {stats.sumTotalOrderCost}</h5>
                    <p className="product-list-slider-text">Cost</p>
                  </div>
                </div>
              </div>
              <div>
                <img
                  src={icon4}
                  className="icon-1-img"
                  alt=""
                  style={{ width: "40px", height: "40px" }}
                />
              </div>
            </div>
          </div>

          <div className="productlist-information-details">
            <div className="account-card d-flex p-3">
              <div>
                <h4 className="pb-4">Stocks Availability</h4>
                <div className="d-flex gap-3">
                  <div style={{ textAlign: "left" }}>
                    <h5 className="product-list-slider">{stats.inStockCount}</h5>
                    <p className="product-list-slider-text" style={{ color: "#28C76F" }}>In Stock</p>
                  </div>
                  <div style={{ textAlign: "left" }}>
                    <h5 className="product-list-slider">{stats.lowStockCount}</h5>
                    <p className="product-list-slider-text" style={{ color: "orange" }}>Low Stocks</p>
                  </div>
                  <div style={{ textAlign: "left" }}>
                    <h5 className="product-list-slider">{stats.outofStockCount}</h5>
                    <p className="product-list-slider-text" style={{ color: "red" }}>Out of Stocks</p>
                  </div>
                </div>
              </div>
              <div>
                <img
                  src={icon3}
                  className="icon-1-img"
                  alt=""
                  style={{ width: "40px", height: "40px" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="product-column d-flex justify-content-between">
          <h5>Products</h5>
          <div className="d-flex gap-2 productList-mobile">
            <button
              className="add-product-button"
              onClick={handleOpenAddPostModal}
            >
              Add Product
            </button>

            <Select
              options={bulkActions}
              // mode="multiple"
              placeholder="Filter"
              className="status-option"
              style={{ height: "39px" }}
              onChange={handleBulkActionsChange}
            />

            <button
              className="filters-button"
              onClick={() => handleExportRows(record)}
            >
              Download all
            </button>
          </div>
        </div>
        <AddProducts
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
        />
      </div>
      <div className="table-list">
        <Table
          className="table-data"
          columns={columns}
          dataSource={paginatedData}
          pagination={false}
          loading={loading}
          onRow={(record) => ({
            onClick: () => handleRowClick(record.productListId),
          })}
        />
        <div className="pagination-info">
          <p>
            Showing{" "}
            <Select
              defaultValue={pagination.pageSize}
              onChange={(value) =>
                handlePageSizeChange(pagination.current, value)
              }
              style={{ width: 80 }}
              suffixIcon={<IoIosArrowDown />}
            >
              <Option value={5}>5</Option>
              <Option value={10}>10</Option>
              <Option value={20}>20</Option>
            </Select>{" "}
            out of {pagination.total}
          </p>
          <Pagination
            current={pagination.current}
            total={pagination.total}
            pageSize={pagination.pageSize}
            showSizeChanger={false}
            onChange={handleTableChange}
          />
        </div>
      </div>
    </div>
  );
};
