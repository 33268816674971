import React, { useState } from "react";
import "./Settings.css";
import { message, Space, Spin, TimePicker } from "antd";
import Instance from "../../AxiosConfig";

const onChange = (time, timeString) => {
  console.log(time, timeString);
};

const StoreInformation = () => {
  const [form, setForm] = useState({
    storeName: "",
    email: "",
    address: "",
    city: "",
    state: "Karnataka",
    zipCode: "",
    country: "",
    phoneNumber: "",
    website: "",
    openingHours: null,
    closingHours: null,
    latitude: "",
    longitude: "",
    storeManager: "",
    description: "",
    // enableRatings: "",
    // enableReviews: "",
    baseDeliveryCharge: "",
    extraDeliveryChargePerKm: "",
  });

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const validate = () => {
    const newErrors = {};
    if (!form.storeName) newErrors.storeName = "Store Name is required";
    if (!form.email) newErrors.email = "Email is required";
    else if (!/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(form.email))
      newErrors.email = "Email is invalid.";
    if (!form.address) newErrors.address = "Store Address is required";
    if (!form.city) newErrors.city = "City is required";
    if (!form.state) newErrors.state = "State is required";
    if (!form.zipCode) newErrors.zipCode = "ZipCode is required";
    else if (!/^\d{1,6}$/.test(form.zipCode))
      newErrors.zipCode = "ZipCode must be a number up to 6 digits";
    if (!form.country) newErrors.country = "Country is required";
    if (!form.phoneNumber) newErrors.phoneNumber = "Phone Number is required";
    else if (!/^\d{10}$/.test(form.phoneNumber))
      newErrors.phoneNumber = "Phone Number must be 10 digits";
    if (!form.website) newErrors.website = "Website is required";
    if (!form.latitude) newErrors.latitude = "Latitude is required";
    else if (form.latitude.length > 20) {
      newErrors.latitude = "Latitude must be up to 20 characters";
    }
    if (!form.longitude) newErrors.longitude = "Longitude is required";
    else if (form.longitude.length > 20) {
      newErrors.longitude = "Longitude must be up to 20 characters";
    }
    if (!form.openingHours)
      newErrors.openingHours = "Opening Hours are required";
    if (!form.closingHours)
      newErrors.closingHours = "Closing Hours are required";
    if (!form.baseDeliveryCharge) newErrors.baseDeliveryCharge = "Base delivery charge is required";
    if (!form.extraDeliveryChargePerKm) newErrors.extraDeliveryChargePerKm = "Extra delivery charge per KM is required";

    if (!form.storeManager)
      newErrors.storeManager = "Store Manager is required";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    let newErrors = { ...errors }; // Get a copy of current errors state
  
    if (name === "website") {
      const websiteRegex = /^(https?:\/\/)?([\w-]+\.)+[a-zA-Z]{2,7}(\/[\w-]*)*$/; // Basic URL validation
      setForm({ ...form, [name]: value });
  
      // If the entered value is valid, clear the error
      if (websiteRegex.test(value) && newErrors[name]) {
        delete newErrors[name];
      }
    } else if (name === "baseDeliveryCharge" || name === "extraDeliveryChargePerKm") {
      const numericValue = value.replace(/[^0-9.]/g, "");
      const parts = numericValue.split('.');
      if (parts.length > 2) return;
    
      setForm({ ...form, [name]: numericValue });
  
      if (newErrors[name]) delete newErrors[name];
    } else if (name === "zipCode") {
      const numericValue = value.replace(/\D/g, "").slice(0, 6);
      setForm({ ...form, [name]: numericValue });
  
      if (/^\d{1,6}$/.test(numericValue) && newErrors[name]) delete newErrors[name];
    } else if (name === "phoneNumber") {
      let numericValue = value.replace(/\D/g, "").slice(0, 10);
      if (numericValue.length > 0) {
        if (numericValue[0] < '4' || numericValue[0] > '9') {
          numericValue = numericValue.slice(1);
        }
      }
      setForm({ ...form, [name]: numericValue });
  
      if (/^\d{10}$/.test(numericValue) && newErrors[name]) delete newErrors[name];
    } else if (name === "latitude" || name === "longitude") {
      if (value.length <= 20) {
        setForm({ ...form, [name]: value });
  
        if (newErrors[name]) delete newErrors[name];
      }
    } else {
      setForm({ ...form, [name]: value });
  
      if (name === "email" && /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(value) && newErrors[name]) {
        delete newErrors[name];
      }
      if ((name === "storeName" || name === "address" || name === "city" || name === "state" || name === "country" || name === "storeManager") && value && newErrors[name]) {
        delete newErrors[name];
      }
    }
  
    setErrors(newErrors); // Update the errors state
  };
  

  const formatTime = (time) => {
    if (!time) return "";
    const hours = time.getHours().toString().padStart(2, "0");
    const minutes = time.getMinutes().toString().padStart(2, "0");
    return `${hours}:${minutes}`;
  };
  
  const handleTimeChange = (field, time) => {
    const formattedTime = time ? formatTime(time.toDate()) : null;
    
    // Create a copy of current errors
    let newErrors = { ...errors };
    
    // If the time is valid, clear any existing error for this field
    if (formattedTime && newErrors[field]) {
      delete newErrors[field];
    }
    
    // Update form state with the formatted time
    setForm({
      ...form,
      [field]: formattedTime,
    });
  
    // Update errors state
    setErrors(newErrors);
  };
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      setLoading(true);
      console.log("Form data to be sent:", form);

      const formData = new FormData();
      for (const key in form) {
        if (form.hasOwnProperty(key)) {
          if (key === "openingHours" || key === "closingHours") {
            const timeValue = form[key];
            formData.append(key, timeValue || ""); // Use formatted time or empty string
          } else {
            formData.append(key, form[key]);
          }
        }
      }
      try {
        const response = await Instance.post(
          "/api/v1/store-info/create",
          form,
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem("adminAuthToken")}`,
              "Content-Type": "application/json",
            },
          }
        );
        console.log("Form submitted successfully:", response.data);
        // setForm({
        //   ...form,
        //   storeName: response.data.storeName,
        //   email: response.data.email,
        //   address: response.data.address,
        //   city: response.data.city,
        //   state: response.data.state,
        //   zipCode: response.data.zipCode,
        //   country: response.data.country,
        //   phoneNumber: response.data.phoneNumber,
        //   website: response.data.website,
        //   openingHours: response.data.openingHours
        //     ? formatTime(new Date(response.data.openingHours))
        //     : null,
        //   closingHours: response.data.closingHours
        //     ? formatTime(new Date(response.data.closingHours))
        //     : null,
        //   latitude: response.data.latitude,
        //   longitude: response.data.longitude,
        //   storeManager: response.data.storeManager,
        //   description: response.data.description,
        //   // enableRatings: response.data.enableRatings,
        //   // enableReviews: response.data.enableReviews,
        //   baseDeliveryCharge: response.data.baseDeliveryCharge,
        //   extraDeliveryChargePerKm: response.data.extraDeliveryChargePerKm
        // });
        setForm({
          storeName: "",
          email: "",
          address: "",
          city: "",
          state: "",
          zipCode: "",
          country: "",
          phoneNumber: "",
          website: "",
          openingHours: null,
          closingHours: null,
          latitude: "",
          longitude: "",
          storeManager: "",
          description: "",
          baseDeliveryCharge: "",
          extraDeliveryChargePerKm: ""
        });
        message.success("Store information saved successfully!");
        console.log("submitted store:", response.data);
        setErrorMessage("");
        setLoading(false)
      } catch (error) {
        const errorMessage = error.response && error.response.data && error.response.data.message
          ? error.response.data.message
          : "Failed to save store information. Please try again.";

        message.error(errorMessage);

        console.error(
          "Error submitting form:",
          error.response ? error.response.data : error.message
        );
        setSuccessMessage("");
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div>
      <h4 className="text-align-left d-flex pb-2">Add Store Information</h4>
      <form onSubmit={handleSubmit}>
        <div className="row pb-2 settings-personalinfo-row">
          <div className="col">
            <label className="text-align-left d-flex pb-2">
              Store Name<span className="required-star">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              name="storeName"
              placeholder="Enter Store Name"
              value={form.storeName}
              onChange={handleChange}
              style={{ padding: "7px", borderRadius: "7px" }}
            />
            {errors.storeName && (
              <span className="text-danger">{errors.storeName}</span>
            )}
          </div>
          <div className="col storeinfo-email">
            <label className="text-align-left d-flex pb-2">
              Email<span className="required-star">*</span>
            </label>
            <input
              type="email"
              className="form-control"
              name="email"
              placeholder="Enter Email Address"
              value={form.email}
              onChange={handleChange}
              style={{ padding: "7px", borderRadius: "7px" }}
            />
            {errors.email && (
              <span className="text-danger">{errors.email}</span>
            )}
          </div>
        </div>
        <div className="row pb-4 settings-personalinfo-row">
          <div className="col">
            <label className="text-align-left d-flex pb-2">
              Store Address<span className="required-star">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              name="address"
              placeholder="Enter Store Address"
              value={form.address}
              onChange={handleChange}
              style={{ padding: "7px", borderRadius: "7px" }}
            />
            {errors.address && (
              <span className="text-danger">{errors.address}</span>
            )}
          </div>
        </div>
        <div className="row pb-2 settings-personalinfo-row mt-1">
          <div className="col storeinfo-email">
            <label className="text-align-left d-flex pb-2">
              City<span className="required-star">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              name="city"
              placeholder="Enter the City"
              value={form.city}
              onChange={handleChange}
              style={{ padding: "7px", borderRadius: "7px" }}
            />
            {errors.city && <span className="text-danger">{errors.city}</span>}
          </div>
          <div className="col">
            <label
              htmlFor="inputState"
              className="form-label text-align-left d-flex"
            >
              State<span className="required-star">*</span>
            </label>
            <select
              id="inputState"
              className="form-select store-information-state-options"
              name="state"
              value={form.state}
              onChange={handleChange}
              style={{borderRadius: "7px" }}
            >
              <option>Karnataka</option>
              <option>Tamil Nadu</option>
              <option>Andhra Pradesh</option>
              <option>Kerala</option>
              <option>Arunachal Pradesh</option>
              <option>Assam</option>
              <option>Bihar</option>
              <option>Chhattisgarh</option>
              <option>Goa</option>
              <option>Gujarat</option>
              <option>Haryana</option>
              <option>Himachal Pradesh</option>
              <option>Jharkhand</option>
              <option>Madhya Pradesh</option>
              <option>Maharashtra</option>
              <option>Manipur</option>
              <option>Meghalaya</option>
              <option>Mizoram</option>
              <option>Nagaland</option>
              <option>Odisha</option>
              <option>Punjab</option>
              <option>Rajasthan</option>
              <option>Sikkim</option>
              <option>Telangana</option>
              <option>Tripura</option>
              <option>Uttar Pradesh</option>
              <option>Uttarakhand</option>
              <option>West Bengal</option>
              <option>Chandigarh</option>
              <option>Delhi</option>
              <option>Jammu and Kashmir</option>
            </select>
            {errors.state && (
              <span className="text-danger">{errors.state}</span>
            )}
          </div>
        </div>
        <div className="row pb-2 settings-personalinfo-row mt-3">
          <div className="col storeinfo-email">
            <label className="text-align-left d-flex pb-1">
              Zip Code<span className="required-star">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              name="zipCode"
              placeholder="Enter the Zip Code"
              value={form.zipCode}
              onChange={handleChange}
              style={{ padding: "7px", borderRadius: "7px" }}
            />
            {errors.zipCode && (
              <span className="text-danger">{errors.zipCode}</span>
            )}
          </div>
          <div className="col">
            <label className="text-align-left d-flex pb-1">
              Country<span className="required-star">*</span>
            </label>
            <select
              className="form-select store-information-state-options"
              name="country"
              value={form.country}
              onChange={handleChange}
              style={{  borderRadius: "7px" }}
            >
              <option value="">Select Country</option>
              <option value="India">India</option>
            </select>
            {errors.country && <span className="text-danger">{errors.country}</span>}
          </div>

        </div>
        <div className="row pb-2 settings-personalinfo-row mt-2">
          <div className="col">
            <label className="text-align-left d-flex pb-2">
              Phone Number<span className="required-star">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              name="phoneNumber"
              placeholder="Enter Phone Number"
              value={form.phoneNumber}
              onChange={handleChange}
              style={{ padding: "7px", borderRadius: "7px" }}
            />
            {errors.phoneNumber && (
              <span className="text-danger">{errors.phoneNumber}</span>
            )}
          </div>
          <div className="col storeinfo-email">
            <label className="text-align-left d-flex pb-2">
              Website<span className="required-star">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              name="website"
              placeholder="Enter Website URL"
              value={form.website}
              onChange={handleChange}
              style={{ padding: "7px", borderRadius: "7px" }}
            />
            {errors.website && (
              <span className="text-danger">{errors.website}</span>
            )}
          </div>
        </div>
        <div className="row pb-2 settings-personalinfo-row mt-3">
          <div className="col">
            <label className="text-align-left d-flex pb-2">
              Opening Hours<span className="required-star">*</span>
            </label>
            <TimePicker
              use12Hours
              format="HH:mm:ss A"
              onChange={(time) => handleTimeChange("openingHours", time)}
              style={{ width: "100%", borderRadius: "7px", padding: "7px" }}
              className="time-sector-width"
            />
            {errors.openingHours && (
              <span className="text-danger">{errors.openingHours}</span>
            )}
          </div>
          <div className="col storeinfo-email">
            <label className="text-align-left d-flex pb-2">
              Closing Hours<span className="required-star">*</span>
            </label>
            <TimePicker
              use12Hours
              format="HH:mm:ss A"
              onChange={(time) => handleTimeChange("closingHours", time)}
              style={{ width: "100%", borderRadius: "7px", padding: "7px" }}
              className="time-sector-width"
            />
            {errors.closingHours && (
              <span className="text-danger">{errors.closingHours}</span>
            )}
          </div>
        </div>
        <div className="row pb-2 settings-personalinfo-row mt-2">
          <div className="col">
            <label className="text-align-left d-flex pb-2">
              Latitude<span className="required-star">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              name="latitude"
              placeholder="Enter the Latitude"
              value={form.latitude}
              onChange={handleChange}
              onKeyPress={(event) => {
                // Allow only numbers, decimal points, and control keys
                const keyCode = event.key;
                if (!/^[0-9.-]+$/.test(keyCode) && !["Backspace", "ArrowLeft", "ArrowRight"].includes(keyCode)) {
                  event.preventDefault();
                }
              }}
              style={{ padding: "7px", borderRadius: "7px" }}
            />
            {errors.latitude && (
              <span className="text-danger">{errors.latitude}</span>
            )}
          </div>

          <div className="col storeinfo-email">
            <label className="text-align-left d-flex pb-2">
              Longitude<span className="required-star">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              name="longitude"
              placeholder="Enter the Longitude"
              value={form.longitude}
              onChange={handleChange}
              onKeyPress={(event) => {
                const keyCode = event.key;
                if (!/^[0-9.-]+$/.test(keyCode) && !["Backspace", "ArrowLeft", "ArrowRight"].includes(keyCode)) {
                  event.preventDefault();
                }
              }}
              style={{ padding: "7px", borderRadius: "7px" }}
            />
            {errors.longitude && (
              <span className="text-danger">{errors.longitude}</span>
            )}
          </div>
        </div>
        <div className="row pb-2 settings-personalinfo-row mt-3">
          <div className="col">
            <label className="text-align-left d-flex pb-2">
              Store Manager<span className="required-star">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              name="storeManager"
              placeholder="Enter Store Manager's Name"
              value={form.storeManager}
              onChange={(e) => {
                const sanitizedValue = e.target.value.replace(/[^a-zA-Z\s]/g, "");
                if (sanitizedValue.length <= 16) {
                  handleChange(e);
                }
              }}
              onKeyDown={(e) => {
                const allowedChars = /^[a-zA-Z\s]*$/;
                if (!allowedChars.test(e.key) && !['Backspace', 'ArrowLeft', 'ArrowRight', 'Tab', 'Delete'].includes(e.key)) {
                  e.preventDefault();
                }
              }}
              style={{ padding: "7px", borderRadius: "7px" }}
            />
            {errors.storeManager && (
              <span className="text-danger">{errors.storeManager}</span>
            )}
          </div>
          <div className="col storeinfo-email">
            <label className="text-align-left d-flex pb-2">Description</label>
            <textarea
              className="form-control"
              name="description"
              placeholder="Enter brief description about the store"
              value={form.description}
              onChange={handleChange}
              id="floatingTextarea"
            ></textarea>
          </div>
        </div>
        
        <h5 className="text-align-left d-flex pt-2">Add Delivery Charges</h5>
        <div className="row pb-2 settings-personalinfo-row mt-3">
          <div className="col storeinfo-email">
            <label className="text-align-left d-flex pb-2">
              Basic Delivery Charge<span className="required-star">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              name="baseDeliveryCharge"
              placeholder="Delivery charge bellow 5 KM"
              value={form.baseDeliveryCharge}
              onChange={handleChange}
              style={{ padding: "7px", borderRadius: "7px" }}
            />
            {errors.baseDeliveryCharge && (
              <span className="text-danger">{errors.baseDeliveryCharge}</span>
            )}
          </div>
          <div className="col">
            <label className="text-align-left d-flex pb-2">
              Extra Delivery Charge per KM<span className="required-star">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              name="extraDeliveryChargePerKm"
              placeholder="Delivery charge above 5 KM"
              value={form.extraDeliveryChargePerKm}
              onChange={handleChange}
              style={{ padding: "7px", borderRadius: "7px" }}
            />
            {errors.extraDeliveryChargePerKm && (
              <span className="text-danger">{errors.extraDeliveryChargePerKm}</span>
            )}
          </div>
        </div>
        <div className="d-flex justify-content-end pt-4 gap-2">
          {/* <button
            type="button"
            style={{
              backgroundColor: "#f6f6f6",
              border: "none",
              borderRadius: "15px",
              padding: "10px 20px",
            }}
          >
            Discard
          </button> */}
          <button
            type="submit"
            style={{
              backgroundColor: "#1b3a57",
              border: "none",
              borderRadius: "15px",
              color: "#fff",
              padding: "10px 20px",
            }}
          >
            {loading ? (
              <Spin size="small" />
            ) : (
              "Save Changes"
            )}

          </button>
        </div>
      </form>
    </div>
  );
};

export default StoreInformation;