import React, { useEffect, useState } from "react";
import { FiArrowUp } from "react-icons/fi";
import "./Dashboard.css";
import Instance from "../../AxiosConfig";
import CardGraph from "./cardGraph";
import { Spin } from "antd";
import productImage from "../../Assets/images/productIcon2.png"
import orderIcon from "../../Assets/images/order-icon-1.png"
import salesIccon from "../../Assets/images/sales-icon-1.png"


const TotalOrders = () => {
  const [data, setData] = useState({
    totalOrdersLast7Days: 0,
    totalProductsLast7Days: 0,
    totalSellingLast7Days: 0,
    orders: [],
    weeklyOrderCounts: [],
    weeklyProductCounts: [],
    weeklySellingCounts: [],
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchOrdersData();
  }, []);

  const fetchOrdersData = async () => {
    setLoading(true);
    try {
      const url = "/api/v1/admin/orders/dashboard";
      const response = await Instance.get(url);
      if (response.status === 200 || response.status === 201) {
        const ordersData = response.data;
        setData(ordersData);
        console.log("Fetched data:", ordersData);
      }
    } catch (error) {
      console.error("Failed to fetch data:", error);
    }
    setLoading(false);
  };

  return (
    <div className="total-cards-div ">
      <div className="total-orders-card col-lg-4 col-md-12">
        <div className="total-orders-div">
          <div className="d-flex gap-5 justify-content-between">
            <h4 style={{ lineHeight: "1" }}>Total Products</h4>
            <div>
              {loading ? (
                <p>Loading...</p>
              ) : (
                <h2>
                  {data.totalProductsLast7Days > 999
                    ? (data.totalProductsLast7Days / 1000).toFixed(2) + "K"
                    : data.totalProductsLast7Days}
                </h2>
              )}
            </div>
            <div>
              <img src={productImage} alt="" style={{width:45}} />
            </div>
          </div>
          <p style={{ fontSize: '13px', lineHeight: "1", marginTop: "-10px" }}>Current Month</p>

          {/* <p className="days-last">
            <FiArrowUp style={{ color: "#1EB564", width: "20px", height: "20px" }} />{" "}
            <span style={{ color: "#1EB564" }}>6%</span> vs last 7 days
          </p> */}
        </div>
        {
          loading ? (
            <Spin />
          ) : (
            < CardGraph
              labels={Object.keys(data.weeklyProductCounts)}
              data={Object.values(data.weeklyProductCounts)}
              label="No of Products"
            />
          )
        }
      </div>
      <div className="total-orders-card col-lg-4 ">
        <div className="total-orders-div">
          <div className="d-flex gap-5 justify-content-between">
            <h4 style={{ lineHeight: "1" }}>Total Orders</h4>
            <div>
              {loading ? (
                <p>Loading...</p>
              ) : (
                <h2>
                  {data.totalOrdersLast7Days > 999
                    ? (data.totalOrdersLast7Days / 1000).toFixed(2) + "K"
                    : data.totalOrdersLast7Days}
                </h2>
              )}
            </div>
            <div>
              <img src={orderIcon} alt="" style={{width:50}} />
            </div>
          </div>
          <p style={{ fontSize: '13px', lineHeight: "1", marginTop: "-10px" }}>Current Month</p>

          {/* <p className="days-last">
            <FiArrowUp style={{ color: "#1EB564", width: "20px", height: "20px" }} />{" "}
            <span style={{ color: "#1EB564" }}>6%</span> vs last 7 days
          </p> */}
        </div>
        {loading ? (
          <Spin />
        ) : (
          <CardGraph
            labels={Object.keys(data.weeklyOrderCounts)}
            data={Object.values(data.weeklyOrderCounts)}
            label="No of Orders"
          />
        )}
      </div>
      <div className="total-orders-card col-lg-4">
        <div className="total-orders-div">
          <div className="d-flex gap-5 justify-content-between">
            <h4 style={{ lineHeight: "1" }}>Total Sales</h4>
            <div>
              {loading ? (
                <p>Loading...</p>
              ) : (
                <h2>
                  {data.totalSellingLast7Days > 999
                    ? (data.totalSellingLast7Days / 1000).toFixed(2) + "K"
                    : data.totalSellingLast7Days}
                </h2>
              )}
            </div>
            <div>
              <img src={salesIccon} alt="" style={{width:40}} />
            </div>
          </div>
          <p style={{ fontSize: '13px', lineHeight: "1" }}>Current Month</p>

          {/* <p className="days-last">
            <FiArrowUp style={{ color: "#1EB564", width: "20px", height: "20px" }} />{" "}
            <span style={{ color: "#1EB564" }}>12%</span> vs last 7 days
          </p> */}
        </div>
        {
          loading ? (
            <Spin />
          ) : (
            <CardGraph
              labels={Object.keys(data.weeklySellingCounts)}
              data={Object.values(data.weeklySellingCounts)}
              label="No of Sales"
            />
          )
        }

      </div>
      
    </div>
  );
};

export default TotalOrders;
