
import React from 'react';
import { MdPerson } from 'react-icons/md';
import { Rate } from 'antd';
import Instance from '../../AxiosConfig';
import { useNavigate } from 'react-router-dom';

const NotificationModelContact = ({ contact, setIsModalVisible }) => {
    const navigate=useNavigate();
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString() + ' ' + date.toLocaleTimeString();
    };
    console.log("NOTIFICATION CONTACT", contact)
    const handleClick = async (contactUsId) => {
        try {
            const url = `/api/v1/notification/markAsRead/contactUs/${contactUsId}`;
            await Instance.put(url, null, {
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem("adminAuthToken")}`,
                    'Content-Type': 'application/json',
                },
            });
            navigate("/contact-us")
            setIsModalVisible(false);
        } catch (error) {
            console.error("Failed to mark notification as read:", error);
        }
    };
    return (
        <div className='scrollable-container notification-scrollbar-container'>
            <div className='container'>
                <div className='row'>
                    {contact?.length === 0 ? (
                        <p style={{ textAlign: "center" }}>No contact available.</p>
                    ) : (
                        contact?.map((contacts, index) => (
                            <div key={index} className="col-12 notification-model-menu-divs">
                                <div className=''>
                                    <div className='notification-page-dot'></div>
                                </div>
                                <div onClick={() => handleClick(contacts.contactUsId)} style={{ cursor: "pointer" }}>
                                    <p>New contact request from
                                        <span className="notification-model-menu-span"> {contacts.name}</span> for service
                                        <span className="notification-model-menu-span"> {contacts.services}</span>
                                    </p>

                                    <div className="notifiation-model-icon-div align-content-center">
                                        <MdPerson className="notification-model-human-icon" style={{ fontSize: "30px", color: "#ED785E" }} />
                                        <p style={{ marginBottom: "0", textAlign: "center" }}>
                                            <span className="notification-model-menu-span">{contacts.name}</span> sent a message on {contacts.createdDate}
                                        </p>
                                    </div>

                                    <p style={{ marginLeft: "40px", marginBottom: "0", }}> <span style={{color:"gray", fontWeight:"600"}}>Message :</span> " {contacts.message} "</p>

                                    <p style={{ marginLeft: "40px", fontWeight:500, marginBottom: "0", marginTop:"5px"  }}> <span style={{color:"gray", fontWeight:"600"}}>Subject :</span>  {contacts.subject}</p>
                                </div>

                            </div>
                        ))
                    )}
                </div>
            </div>
        </div>
    );
};

export default NotificationModelContact;
