import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Input, Spin } from "antd";
import { RiArrowLeftWideFill } from "react-icons/ri";
import bgLogo from "../../Assets/images/white-bg-logo.png";
import Instance from "../../AxiosConfig";
import "./Auth.css";

const ForgotPassword = () => {
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState("");
    const [responseMessage, setResponseMessage] = useState("");
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    const handleBackToLogin = () => {
        navigate("/");
    };

    const handleContinue = async () => {
        if (!email) {
            setEmailError("Please enter your email address.");
            return;
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            setEmailError("Please enter a valid email address.");
            return;
        }
        setLoading(true);
        try {
            const response = await Instance.post("/api/v1/forgotPassword/verifyMail", {
                email
            });
            console.log("Form submitted", response.data);
            setResponseMessage(response.data);
            navigate("/verify-code", { state: { email } });
        } catch (error) {
            console.error("Error during form submission:", error);
            if (error.response) {
                console.error("Error response:", error.response);
                let errorMessage = "Login failed!";
                if (error.response.data && error.response.data.message) {
                    const serverMessage = error.response.data.message;
                    if (serverMessage.includes(":")) {
                        errorMessage = serverMessage.split(":")[0];
                    } else {
                        errorMessage = serverMessage;
                    }
                }
                setEmailError(errorMessage);
            } else {
                setEmailError("Login failed!");
            }
        }
        setLoading(false);
    };
    const handleKeyPress = (e) => {
        if (e.key === "Enter") {
          handleContinue();
        }
      };
      
    return (
        <>
            <div className="forgot-password-container">
                <div className="col-lg-7"></div>
                <div className="col-lg-5">
                    {/* <div style={{ position: "fixed", top: "10px", right: "10px", padding: "20px" }}>
                        <img onClick={handleBackToLogin} src={bgLogo} alt="" style={{ width: "200px", height: "50px" }} />
                    </div> */}
                    <div className="forgot-details">
                        <button className="pb-4" style={{ border: "none", backgroundColor: "#fff", paddingLeft: "0px" }} onClick={handleBackToLogin}>
                            <RiArrowLeftWideFill style={{ fontSize: "20px" }} /> Back to login
                        </button>
                        <h2>Forgot your Password?</h2>
                        <p style={{ color: "#313131", fontSize: "15px" }}>Don’t worry, happens to all of us. Enter your email below to recover your password</p>
                        <form className="login-form">
                            <label htmlFor="email" className="mt-4">Email Address</label>
                            <div className="email-container">
                                <Input
                                    type="email"
                                    id="email"
                                    name="email"
                                    placeholder="Enter Your Email Address"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    onKeyPress={handleKeyPress}
                                />
                            </div>
                            {emailError && <p style={{ color: "red" }}>{emailError}</p>}
                            <div className="d-flex button-container gap-4 pt-4">
                                <button className="login-btn" id="login-button" onClick={handleContinue} disabled={loading}>
                                    {loading ? <Spin /> : "Submit"}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ForgotPassword;
