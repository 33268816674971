import React, { useState, useEffect } from "react";
import { Table, Input, Select, Menu, Dropdown, Pagination, DatePicker, Spin } from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import { MdOutlineArrowDropDownCircle } from "react-icons/md";
import { SearchOutlined } from "@ant-design/icons";
import { BsThreeDots } from "react-icons/bs";
import { IoMdPrint, IoIosArrowDown } from "react-icons/io";
import moment from "moment";
import jsPDF from 'jspdf';
import InvoiceLogo1 from '../../Assets/images/white-bg-logo.png'

const { Option } = Select;

const ShippedStatusList = () => {
    const location = useLocation();
    const { userData } = location.state || {};
    console.log("adf", userData);

    const [searchText, setSearchText] = useState("");
    const [expandedRowKeys, setExpandedRowKeys] = useState([]);
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 5,
        total: 0,
    });
    const [loading, setLoading] = useState(true);
    const [selectedDate, setSelectedDate] = useState(null);
    const navigate = useNavigate();



    useEffect(() => {
        setLoading(true)
        if (userData && userData.content) {
            const completedOrders = userData.content.filter(
                (order) => order.orderStatus === "SHIPPED"
            );
            setData(completedOrders);
            if (completedOrders.length) {
                setPagination((prev) => ({
                    ...prev,
                    total: completedOrders.length,
                }));
                setLoading(false);
            }
        }
        setLoading(false)
    }, [userData]);

    useEffect(() => {
        filterData();
    }, [searchText, selectedDate, data]);

    const drawBorder = (doc) => {
        const pageWidth = doc.internal.pageSize.width;
        const pageHeight = doc.internal.pageSize.height;
        const borderMargin = 7;
        // Optionally set the line color or width
        doc.setLineWidth(0.3);
        doc.setDrawColor(0);
        doc.rect(borderMargin, borderMargin, pageWidth - 2 * borderMargin, pageHeight - 2 * borderMargin);
    };
    const handleExportRows = (record) => {
        console.log("pdfdata:", record);
        const doc = new jsPDF();

        const logoImage = new Image();
        logoImage.src = InvoiceLogo1;

        logoImage.onload = function () {
            doc.setFillColor("#fff");
            doc.rect(0, 0, doc.internal.pageSize.width, 40, "F");
            doc.addImage(logoImage, "PNG", 15, 10, 50, 20);

            doc.setFontSize(11);
            doc.setTextColor(80);

            const baseX = 137;
            const colonX = 161;
            const valueX = 163;
            const lineHeight = 7;

            doc.text("Order ID:", baseX, 12);
            doc.text("Name:", baseX, 12 + lineHeight);
            doc.text("Order date:", baseX, 12 + 2 * lineHeight);
            doc.text("Total Items:", baseX, 12 + 3 * lineHeight);

            doc.text(":", colonX, 12);
            doc.text(":", colonX, 12 + lineHeight);
            doc.text(":", colonX, 12 + 2 * lineHeight);
            doc.text(":", colonX, 12 + 3 * lineHeight);

            doc.text(`${record.orderId}`, valueX, 12);
            doc.text(`${record.username}`, valueX, 12 + lineHeight);
            doc.text(`${record.orderDate}`, valueX, 12 + 2 * lineHeight);
            doc.text(`${record.totalItem}`, valueX, 12 + 3 * lineHeight);

            drawBorder(doc);
            const pageWidth = doc.internal.pageSize.width;
            const pageHeight = doc.internal.pageSize.height;
            const borderMargin = 7;

            doc.rect(borderMargin, borderMargin, pageWidth - 2 * borderMargin, pageHeight - 2 * borderMargin);

            const tableHeaders = [
                "NAME",
                "PRICE",
                "QTY",
                "DISCOUNT%",
                "TOTAL",
                "PAYMENT STATUS"
            ];

            const groupedOrderItems = record.orderItems.reduce((acc, item) => {
                if (!acc[item.productId]) {
                    acc[item.productId] = { ...item, quantity: 0, totalPrice: 0 };
                }
                acc[item.productId].quantity += item.quantity;
                acc[item.productId].totalPrice += item.discountedPrice;
                return acc;
            }, {});

            const tableData = Object.values(groupedOrderItems).map(item => [
                item.title,
                item.totalPrice.toFixed(2),
                item.quantity,
                item.discountPercentage,
                item.totalPrice.toFixed(2),
                record.paymentDetails.paymentStatus || 'N/A',
            ]);

            doc.autoTable({
                head: [tableHeaders],
                body: tableData,
                startY: 40,
                headStyles: { fillColor: [27, 58, 87], textColor: [255, 255, 255] },
                styles: { cellPadding: 4, minCellHeight: 8, lineWidth: 0.2, lineColor: [200, 200, 200] },
                columnStyles: {
                    0: { cellWidth: 37 },
                    3: { cellWidth: 37 },
                    4: { cellWidth: 29 },
                },
            });

            // Steel-specific table processing
            const processSteelTableData = (data) => {
                let previousBrand = '';
                let processedData = [];

                data.forEach((row) => {
                    if (row[0] === previousBrand) {
                        // Clear the brand cell if it's not the first row of the same brand group
                        row[0] = '';
                    } else {
                        previousBrand = row[0];
                    }
                    processedData.push(row);
                });

                return processedData;
            };

            let steelTableData = (record.orderItems || [])
                .filter(item => item.steelProduct)
                .sort((a, b) => parseFloat(a.steelSize) - parseFloat(b.steelSize))
                .map(item => [
                    item.brand,
                    item.title,
                    item.steelSize,
                    `${(item.price ?? 0).toFixed(2)}`,
                    `${item.discountPercentage}%`,
                    item.quantity,
                    `${(item.discountedPrice ?? 0).toFixed(2)}`,
                ]);

            steelTableData.sort((a, b) => a[0].localeCompare(b[0]));

            steelTableData = processSteelTableData(steelTableData);

            if (steelTableData.length > 0) {
                const steelTableHeaders = [
                    "BRAND",
                    "PRODUCT NAME",
                    "STEEL SIZE",
                    "PRICE",
                    "DISC %",
                    "QTY",
                    "DISC PRICE",
                ];

                doc.autoTable({
                    head: [steelTableHeaders],
                    body: steelTableData,
                    startY: doc.lastAutoTable.finalY + 10,
                    headStyles: {
                        fillColor: [27, 58, 87],
                        textColor: [255, 255, 255],
                    },
                    styles: {
                        cellPadding: 4,
                        minCellHeight: 8,
                        lineWidth: 0.2,
                        lineColor: [200, 200, 200],
                    },
                    columnStyles: {
                        0: { cellWidth: 30 },
                        2: { cellWidth: 20 },
                        4: { cellWidth: 19 },
                    },
                });
            }


            const finalY = doc.lastAutoTable.finalY;
            const labelMarginLeft = 137;
            const valueMarginLeft = 174;

            const labelMarginLeft2 = 14;
            const colonMarginLeft2 = 40;
            const valueMarginLeft2 = 42;


            doc.setFontSize(12);
            doc.setTextColor(80);

            const colonMarginLeft = labelMarginLeft + 33; // Adjusting colon position

            doc.text("Subtotal", labelMarginLeft, finalY + 10);
            doc.text(":", colonMarginLeft, finalY + 10);
            doc.text(`${(record.totalPrice ?? 0).toFixed(2)}`, valueMarginLeft, finalY + 10);

            doc.text("Discount Amount", labelMarginLeft, finalY + 20);
            doc.text(":", colonMarginLeft, finalY + 20);
            doc.text(`-${(record.discount ?? 0).toFixed(2)}`, valueMarginLeft, finalY + 20);

            if (record.couponApplied) {
                doc.text("Coupon Savings", labelMarginLeft, finalY + 30);
                doc.text(":", colonMarginLeft, finalY + 30);
                doc.text(`-${(record.couponDiscountedPrice ?? 0).toFixed(2)}`, valueMarginLeft, finalY + 30);
            }

            doc.text("Shipping", labelMarginLeft, finalY + 30 + (record.couponApplied ? 10 : 0));
            doc.text(":", colonMarginLeft, finalY + 30 + (record.couponApplied ? 10 : 0));
            doc.text(`${(record.deliveryFee ?? 0).toFixed(2)}`, valueMarginLeft, finalY + 30 + (record.couponApplied ? 10 : 0));

            doc.line(labelMarginLeft, finalY + 33 + (record.couponApplied ? 10 : 0), valueMarginLeft + 20, finalY + 33 + (record.couponApplied ? 10 : 0));

            doc.text("Total Amount", labelMarginLeft, finalY + 40 + (record.couponApplied ? 10 : 0));
            doc.text(":", colonMarginLeft, finalY + 40 + (record.couponApplied ? 10 : 0));
            doc.text(`${(record.totalPriceIncludeDeliveryFee ?? 0).toFixed(2)}`, valueMarginLeft, finalY + 40 + (record.couponApplied ? 10 : 0));

            if (record.paymentType === "COD") {
                doc.text("Amount Received", labelMarginLeft, finalY + 50 + (record.couponApplied ? 10 : 0));
                doc.text(":", colonMarginLeft, finalY + 50 + (record.couponApplied ? 10 : 0));
                doc.text(`-${(record.paymentDetails.amountReceived ?? 0).toFixed(2)}`, valueMarginLeft, finalY + 50 + (record.couponApplied ? 10 : 0));

                doc.text("Balance Amount", labelMarginLeft, finalY + 60 + (record.couponApplied ? 10 : 0));
                doc.text(":", colonMarginLeft, finalY + 60 + (record.couponApplied ? 10 : 0));
                doc.text(`${((record.totalPriceIncludeDeliveryFee - record.paymentDetails.amountReceived) ?? 0).toFixed(2)}`, valueMarginLeft, finalY + 60 + (record.couponApplied ? 10 : 0));
            }



            doc.setFontSize(11);
            const verticalOffset = record.paymentType === "COD" ? 10 : 0;
            const maxWidth = 90;
            const addressMaxWidth = 90;
            const initialY = finalY + 10;
            let adjustedY = initialY;

            // const colonMarginLeft2 = labelMarginLeft2 + 25;

            if (record.shippingAddress.gstNumber) {
                doc.text("GST Number", labelMarginLeft2, adjustedY);
                doc.text(":", colonMarginLeft2, adjustedY);
                doc.text(`${record.shippingAddress.gstNumber}`, valueMarginLeft2, adjustedY, { maxWidth });
                adjustedY += 10;
            }

            doc.text("Delivery To", labelMarginLeft2, adjustedY);
            doc.text(":", colonMarginLeft2, adjustedY);
            doc.text(`${record.shippingAddress.addressType}`, valueMarginLeft2, adjustedY, { maxWidth });
            adjustedY += 10;

            doc.text("Payment Type", labelMarginLeft2, adjustedY);
            doc.text(":", colonMarginLeft2, adjustedY);
            doc.text(`${record.paymentType}`, valueMarginLeft2, adjustedY, { maxWidth });
            adjustedY += 10;

            if (record.paymentType === "COD") {
                doc.text("Payment Id", labelMarginLeft2, adjustedY);
                doc.text(":", colonMarginLeft2, adjustedY);
                doc.text(`${record.paymentDetails.paymentId}`, valueMarginLeft2, adjustedY, { maxWidth });
                adjustedY += 10;
            }

            doc.text("Mobile No", labelMarginLeft2, adjustedY);
            doc.text(":", colonMarginLeft2, adjustedY);
            doc.text(`+91 ${record.shippingAddress.mobile}`, valueMarginLeft2, adjustedY, { maxWidth });
            adjustedY += 8 + verticalOffset;

            doc.text("Address", labelMarginLeft2, adjustedY);
            doc.text(":", colonMarginLeft2, adjustedY);
            const addressText = `${record.shippingAddress.streetAddress ? record.shippingAddress.streetAddress : "No street address provided"}\n${record.shippingAddress.address}\n${record.shippingAddress.city}, ${record.shippingAddress.state}, ${record.shippingAddress.zipCode} ${record.shippingAddress.country}`;
            const addressLines = doc.splitTextToSize(addressText, addressMaxWidth);

            // Loop through the lines and render each on a new line
            addressLines.forEach((line, index) => {
                doc.text(line, valueMarginLeft2, adjustedY + (index * 8)); // Adjust vertical position for each line
            });

            const totalPages = doc.getNumberOfPages();
            for (let i = 1; i <= totalPages; i++) {
                doc.setPage(i);
                doc.setFontSize(10);
                doc.text(`Page ${i} of ${totalPages}`, pageWidth - 20, pageHeight - 1, {
                    align: 'right',
                });
                drawBorder(doc);
            }

            // const newWindow = window.open();
            const fileName = `Order_${record.orderId}.pdf`;
            doc.save(fileName);
        };
    };



    const filterData = () => {
        let tempData = [...data];
        if (searchText) {
            tempData = tempData.filter((item) =>
                item.orderId.toString().includes(searchText)
            );
        }
        if (selectedDate) {
            tempData = tempData.filter((item) =>
                moment(item.orderDate).isSame(selectedDate, "day")
            );
        }
        setFilteredData(tempData);
        setPagination((prev) => ({
            ...prev,
            total: tempData.length,
            current: 1,
        }));
    };


    const handleToggleDetails = (record) => {
        const key = record.orderId;
        setExpandedRowKeys((prevKeys) =>
            prevKeys.includes(key) ? prevKeys.filter((k) => k !== key) : [...prevKeys, key]
        );
    };

    const handleDateChange = (date, dateString) => {
        setSelectedDate(date ? moment(dateString) : null);
    };

    const handlePageChange = (page, pageSize) => {
        setPagination((prev) => ({
            ...prev,
            current: page,
            pageSize,
        }));
    };
    const [expandedNestedRowKeys, setExpandedNestedRowKeys] = useState([]);

    const handleNestedToggleDetails = (productId) => {
        setExpandedNestedRowKeys((prevKeys) => {
            // Ensure prevKeys is always an array
            const keys = prevKeys || [];
            return keys.includes(productId)
                ? keys.filter(key => key !== productId)
                : [...keys, productId];
        });
    };
    const groupOrderItems = (orderItems) => {
        const groupedItems = {};

        orderItems.forEach((item) => {
            const key = item.productId;

            if (groupedItems[key]) {
                groupedItems[key].quantity += item.quantity;
                groupedItems[key].total += item.price;
                groupedItems[key].totalDiscountedPrice += item.discountedPrice;
                if (groupedItems[key].steelProduct && groupedItems[key].total > 0) {
                    const discount = ((groupedItems[key].total - groupedItems[key].totalDiscountedPrice) / groupedItems[key].total) * 100;
                    groupedItems[key].discountPercentage = discount.toFixed(2);
                }
            } else {
                groupedItems[key] = {
                    ...item,
                    quantity: item.quantity,
                    total: item.price,
                    totalDiscountedPrice: item.discountedPrice,
                    discountPercentage: item.discountPercentage,
                };
                if (item.steelProduct && groupedItems[key].totalPrice > 0) {
                    const discount = ((groupedItems[key].totalPrice - groupedItems[key].totalDiscountedPrice) / groupedItems[key].totalPrice) * 100;
                    groupedItems[key].discountPercentage = discount.toFixed(2);
                }
            }
        });

        return Object.values(groupedItems);
    };


    const handleTableChange = (page, pageSize) => {
        setPagination((prev) => ({
            ...prev,
            current: page,
            pageSize,
        }));
    };


    const columns = [
        {
            title: "ORDER ID",
            dataIndex: "orderId",
            render: (text) => `#${text}`,
        },
        {
            title: "ORDERED DATE",
            dataIndex: "orderDate",
        },
        {
            title: "TOTAL ITEMS",
            dataIndex: "totalItem",
        },
        {
            title: "TOTAL PAYMENT",
            dataIndex: "totalPriceIncludeDeliveryFee",
            render: (text) => (text !== null && text !== undefined ? text.toFixed(2) : "0.00"),
        },
        {
            title: "PAYMENT TYPE",
            dataIndex: "paymentType",
        },
        {
            title: "PAYMENT STATUS",
            dataIndex: ["paymentDetails", "paymentStatus"],
            render: (status) => {
                let color = "";
                let backgroundColor = "";
                if (status === "COMPLETED") {
                    color = "#006400";
                    backgroundColor = "#E0F8E0";
                } else if (status === "PENDING") {
                    color = "#FF8C00";
                    backgroundColor = "#FFE5B4";
                } else if (status === "CANCELLED") {
                    color = "#8B0000";
                    backgroundColor = "#FAD4D4";
                } else if (status === "PROCESSING") {
                    color = "#0044CC";
                    backgroundColor = "#E0F0FF";
                }
                return (
                    <div style={{ width: "110px", backgroundColor, padding: "4px", borderRadius: "5px" }}>
                        <span style={{ color, justifyContent: "center", display: "flex" }}>{status}</span>
                    </div>
                );
            },
        },
        {
            title: "ORDER STATUS",
            dataIndex: "orderStatus",
            render: (status) => {
                let color = "";
                let backgroundColor = "";
                if (status === "DELIVERED") {
                    color = "#006400";
                    backgroundColor = "#E0F8E0";
                } else if (status === "IN_PROGRESS") {
                    color = "#F97316";
                    backgroundColor = "#fee7d7";
                } else if (status === "CANCELLED") {
                    color = "#8B0000";
                    backgroundColor = "#FAD4D4";
                }
                else if (status === "ORDER_PLACED") {
                    color = "#0044CC";
                    backgroundColor = "#E0F0FF";
                } else if (status === "PENDING") {
                    color = "#FF8C00";
                    backgroundColor = "#FFE5B4";
                } else if (status === "SHIPPED") {
                    color = "#b7eb8f";
                    backgroundColor = "#f6ffed";
                }
                return (
                    <div style={{ width: "110px", backgroundColor, padding: "4px", borderRadius: "5px" }}>
                        <span style={{ color, justifyContent: "center", display: "flex" }}>{status}</span>
                    </div>
                );
            },
        },
        {
            render: (_, record) => (
                <MdOutlineArrowDropDownCircle
                    style={{
                        width: "20px",
                        height: "20px",
                        cursor: "pointer",
                        transition: "transform 0.3s",
                        transform: expandedRowKeys.includes(record.orderId)
                            ? "rotate(180deg)"
                            : "rotate(0deg)",
                    }}
                    onClick={() => handleToggleDetails(record)}
                />
            ),
        },
        {
            title: (
                <>
                    <IoMdPrint
                        style={{ fontSize: "20px", verticalAlign: "middle", marginLeft: "4px" }}
                    />{" "}
                    PRINT
                </>
            ),
            dataIndex: "orderId",
            render: (text, record) => (
                <Dropdown
                    overlay={
                        <Menu>
                            <Menu.Item onClick={() => handleExportRows(record)}>
                                <IoMdPrint style={{ marginRight: "8px" }} />
                                Print
                            </Menu.Item>
                        </Menu>
                    }
                >
                    <BsThreeDots style={{ fontSize: "24px" }} />
                </Dropdown>
            ),
        },
    ];

    const expandedRowRender = (record) => {
        const groupedOrderItems = groupOrderItems(record.orderItems);

        return (
            <div className="custom-child-table">
                <Table
                    columns={[
                        { title: "OrderId", dataIndex: "orderId", render: (text) => `#${text}` },
                        {
                            title: "NAME",
                            dataIndex: "title",
                            render: (text) => <span style={{ fontWeight: "600" }}>{text}</span>,
                        },
                        { title: "PRICE", dataIndex: "total", render: (text) => (text !== null && text !== undefined ? text.toFixed(2) : "0.00"), },
                        { title: "QTY", dataIndex: "quantity", render: (text) => `${text}` },
                        {
                            title: "DISCOUNT%",
                            dataIndex: "discountPercentage",
                            render: (text) => `${text}%`,
                        },
                        { title: "TOTAL", dataIndex: "totalDiscountedPrice", render: (text) => (text !== null && text !== undefined ? text.toFixed(2) : "0.00"), },
                        {
                            title: "EXPAND",
                            dataIndex: "expand",
                            render: (_, itemRecord) => (
                                itemRecord.steelProduct ? (
                                    <MdOutlineArrowDropDownCircle
                                        onClick={() => handleNestedToggleDetails(itemRecord.productId)} // Use productId here
                                        style={{
                                            fontSize: "24px",
                                            cursor: "pointer",
                                            transform: expandedNestedRowKeys.includes(itemRecord.productId) ? 'rotate(180deg)' : 'rotate(0deg)'
                                        }}
                                    />
                                ) : null
                            ),
                        },
                    ]}
                    dataSource={groupedOrderItems}
                    rowKey={(item) => item.productId}
                    pagination={false}
                    expandable={{
                        expandedRowRender: (nestedRecord) => {
                            const steelItems = record.orderItems.filter(
                                (item) => item.productId === nestedRecord.productId && item.steelProduct
                            );

                            return (
                                <Table
                                    dataSource={steelItems}
                                    columns={[
                                        {
                                            title: "STEEL SIZE",
                                            dataIndex: "steelSize",
                                            key: "steelSize",
                                            sorter: (a, b) => parseFloat(b.steelSize) - parseFloat(a.steelSize),
                                            defaultSortOrder: 'descend', // Sorts in descending order by default
                                        }, { title: "PRICE", dataIndex: "price", key: "price", render: (text) => (text !== null && text !== undefined ? text.toFixed(2) : "0.00"), },
                                        { title: "DISCOUNT %", dataIndex: "discountPercentage", key: "discountPercentage", render: (text) => `${text}%` },
                                        { title: "DISCOUNT PRICE", dataIndex: "discountedPrice", key: "discountedPrice", render: (text) => (text !== null && text !== undefined ? text.toFixed(2) : "0.00"), },
                                        { title: "QUANTITY", dataIndex: "quantity", key: "quantity" },
                                    ]}
                                    rowKey="steelProductId"
                                    pagination={false}
                                />
                            );
                        },
                        rowExpandable: (nestedRecord) => !!nestedRecord.steelProduct,
                        expandedRowKeys: expandedNestedRowKeys,
                        onExpand: (expanded, nestedRecord) => {
                            handleNestedToggleDetails(nestedRecord.productId); // Use productId here
                        },
                    }}
                />
                <div className="row">
                    <div className="col-lg-7 manageorders-media"
                        style={{ marginTop: 16, display: "flex", gap: "10px" }}>
                        <div className="address-label">
                            {record.shippingAddress.gstNumber && (
                                <p>GST Number</p>
                            )}
                            <p>Delivery To</p>
                            <p style={{ marginTop: -10 }}>PaymentType</p>
                            {record.paymentType == "COD" && <p style={{ marginTop: -10 }}>Payment Id</p>}
                            <p style={{ marginTop: -10 }}>Mobile No</p>
                            <p style={{ marginTop: -10 }}>Address</p>

                        </div>
                        <div>
                            {record.shippingAddress.gstNumber && (<p>:</p>)}
                            <p>:</p>
                            <p style={{ marginTop: -10 }}>:</p>
                            {record.paymentType == "COD" && <p style={{ marginTop: -10 }}>:</p>}
                            <p style={{ marginTop: -10 }}>:</p>
                            <p style={{ marginTop: -10 }}>:</p>
                        </div>
                        <div className="address-value">
                            {record.shippingAddress.gstNumber && (<p>{record.shippingAddress.gstNumber}</p>)}
                            <p>{record.shippingAddress.addressType}</p>
                            <p style={{ marginTop: -10 }}>{record.paymentType}</p>
                            {record.paymentType === "COD" && (
                                <p style={{ marginTop: -10 }}>
                                    {record.paymentDetails.paymentId ? record.paymentDetails.paymentId : "NA"}
                                </p>
                            )}
                            <p style={{ marginTop: -10 }}>+91 {record.shippingAddress.mobile}</p>
                            <p style={{ marginTop: -10 }}>{record.shippingAddress.streetAddress}</p>
                            {record.shippingAddress.address && (
                                <p style={{ marginTop: -10 }}>{record.shippingAddress.address}</p>
                            )}
                            <p style={{ marginTop: -10 }}>{record.shippingAddress.city}, {record.shippingAddress.state}, {record.shippingAddress.zipCode} {record.shippingAddress.country}</p>
                        </div>

                    </div>
                    {/* <div className="col-lg-4"></div> */}
                    <div
                        className="col-lg-4 manageorders-media"
                        style={{ marginTop: 16, display: "flex", gap: "10px" }}
                    >
                        <div className="summary-div">
                            <p>Subtotal </p>
                            <p>Discount </p>
                            {record.couponApplied && record.couponType !== "FREE_SHIPPING" && <p style={{ color: "#54C104" }}>Coupon Savings</p>}
                            <p>Shipping </p>
                            <p style={{ color: "#000000E0" }}>_____________</p>
                            <p>Total </p>
                            {record.paymentType === "COD" && <p>Amount Received</p>}
                            {record.paymentType === "COD" && <p>Balance Amount</p>}
                        </div>
                        <div>
                            <p>:</p>
                            <p>:</p>
                            {record.couponApplied && record.couponType !== "FREE_SHIPPING" && <p style={{ color: "#54C104" }}>:</p>}
                            <p>:</p>
                            <p> &nbsp; </p>
                            <p>:</p>
                            {record.paymentType === "COD" && <p>:</p>}
                            {record.paymentType === "COD" && <p>:</p>}
                        </div>
                        <div>
                            <p> ₹ {(record.totalPrice ?? 0).toFixed(2)}</p>
                            <p>
                                {" "}
                                -<span style={{ color: "#ce1b28" }}>₹ {(record.discount ?? 0).toFixed(2)}</span>
                            </p>
                            {record.couponApplied && record.couponType !== "FREE_SHIPPING" && <p style={{ color: "#54C104" }}>-₹ {(record.couponDiscountedPrice ?? 0).toFixed(2)}</p>}
                            <p style={{ textDecoration: record.couponApplied && record.couponType === "FREE_SHIPPING" ? 'line-through' : 'none' }}> {(record.deliveryFee ?? 0).toFixed(2)}</p>
                            <p style={{ marginLeft: "-35px" }}>____________</p>
                            <p>₹ {(record.totalPriceIncludeDeliveryFee ?? 0).toFixed(2)}</p>
                            {record.paymentType === "COD" && <p>-₹ {(record.paymentDetails.amountReceived ?? 0).toFixed(2)}</p>}
                            {record.paymentType === "COD" && <p>₹ {((record.totalPriceIncludeDeliveryFee - record.paymentDetails.amountReceived) ?? 0).toFixed(2)}</p>}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const getPrintMenu = (record) => (
        <Menu>
            <Menu.Item key="print">
                <span>Print</span>
            </Menu.Item>
        </Menu>
    );

    const currentData = filteredData.slice(
        (pagination.current - 1) * pagination.pageSize,
        pagination.current * pagination.pageSize
    );

    return (
        <div className="user-list-section">
            <div className="filter-row">
                <div className="select-options">
                    <Input
                        placeholder="Search by order id"
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                        className="search-input-table me-2"
                        prefix={<SearchOutlined />}
                    />
                </div>
                <div className="search-table-container">
                    <DatePicker
                        placeholder="Filter by Date"
                        format="YYYY-MM-DD"
                        value={selectedDate ? moment(selectedDate) : null}
                        onChange={handleDateChange}
                        onFocus={() => setSelectedDate(null)}
                        disabledDate={(current) => current && current > moment().endOf('day')}
                        onKeyDown={(e) => {
                            const allowedKeys = /[0-9/-]/;
                            if (
                                !allowedKeys.test(e.key) &&
                                !['Backspace', 'ArrowLeft', 'ArrowRight', 'Tab', 'Delete'].includes(e.key)
                            ) {
                                e.preventDefault();
                            }
                        }}
                    />
                </div>
            </div>
            <div className="table-list">
                {loading ? (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Spin size="large" />
                    </div>
                ) : (
                    <>
                        <Table
                            className="table-data"
                            columns={columns}
                            dataSource={currentData}
                            rowKey={(data) => data.orderId}
                            expandedRowRender={expandedRowRender}
                            expandedRowKeys={expandedRowKeys}
                            onExpand={(expanded, record) => handleToggleDetails(record)}
                            expandIcon={() => null}
                            rowSelection={{
                                type: "checkbox",
                                onChange: (selectedRowKeys, selectedRows) => {
                                    console.log("selectedRowKeys:", selectedRowKeys);
                                    console.log("selectedRows:", selectedRows);
                                },
                            }}
                            pagination={false}
                        />
                    </>
                )}

                <div className="pagination-info">
                    <p>
                        Showing{" "}
                        <Select
                            defaultValue={pagination.pageSize}
                            onChange={(value) =>
                                setPagination((prev) => ({
                                    ...prev,
                                    pageSize: value,
                                    current: 1,
                                }))
                            }
                            style={{ width: 80 }}
                            suffixIcon={<IoIosArrowDown />}
                        >
                            <Option value={5}>5</Option>
                            <Option value={10}>10</Option>
                            <Option value={20}>20</Option>
                        </Select>{" "}
                        out of {pagination.total}
                    </p>
                    <Pagination
                        {...pagination}
                        showSizeChanger={false}
                        onChange={handlePageChange}
                    />
                </div>
            </div>
        </div>
    );
};

export default ShippedStatusList;