import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ProductList } from "./ProductList";
import { SearchOutlined } from "@ant-design/icons";
import { MdOutlineModeEdit } from "react-icons/md";
import { Input, Spin } from "antd";
import Select from "react-select";
import image from "../../Assets/images/product-1.png";
import { IoIosArrowRoundBack } from "react-icons/io";
import Instance from "../../AxiosConfig";
import EditProducts from "./EditProductList";
import default_image from "../../Assets/images/defailt-no-image.png";
import {PRODUCT_LIST_BASE_URL} from "../../GlobalConstant"


const ViewProducts = () => {
  const navigate = useNavigate();
  const { productListId } = useParams();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const bulkActions = [
    { value: "smallScale", label: "Small Scale" },
    { value: "largeScale", label: "Large Scale" },
  ];
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  // const [selectedProduct, setSelectedProduct] = useState(null); 

  const handleOpenAddPostModal = async () => {
    // setSelectedProduct(productListId)
    // console.log("sd",productListId)
    setIsModalVisible(true);
  };
  const fetchProduct = async () => {
    try {
      const response = await Instance.get(`/api/v1/admin/product/${productListId}`);
      if (response.status === 200) {
        setProduct(response.data);
        console.log("PRODUCTS LIST", response.data);
      }
    } catch (error) {
      console.error("Failed to fetch product:", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchProduct();
  }, [productListId]);

  const handlebackbutton = () => {
    navigate('/products-list')
  }
  const [searchText, setSearchText] = useState("");
  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "transparent",
      border: "1px solid #E5E5E5",
      borderRadius: "4px",
      boxShadow: "none",
      cursor: "pointer",
      color: "#ce1b28",
    }),
  };

  if (loading) {
    return <div><Spin /></div>;
  }

  if (!product) {
    return <div>Product not found</div>;
  }

  return (
    <div className="container">
      <div className="main-title-all">
        <div style={{ display: "flex", justifyContent: "start" }}>
          <span>Products List</span>
        </div>
      </div>
      <div className="filter-row">
        {/* <div className="select-options">
          <Input
            placeholder="Search..."
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            className="search-input-table me-2"
            prefix={<SearchOutlined />}
          />
        </div> */}
        {/* <div className="search-table-container">
          <Select
            options={bulkActions}
            isMulti
            placeholder="Filter By Date Range"
            styles={{ ...customStyles, menuPortal: document.body }}
          />
        </div> */}
      </div>
      <div
        className="d-flex justify-content-between"
        style={{ borderBottom: "1px solid #f0f1f3", paddingBottom: "20px" }}
      >
        <h5>{product.productName}</h5>
        <div className="d-flex gap-2">
          <button className="filters-button" onClick={() => handleOpenAddPostModal(productListId)}>
            <MdOutlineModeEdit /> &nbsp; Edit
          </button>
          {/* <button className="filters-button">Download </button> */}
        </div>
      </div>
      <div className="d-flex justify-content-between">
        <div>
          <h6 style={{ textAlign: "left", marginTop: "20px" }}>Primary Details</h6>
          <div className=" view-product-details">
            <div>
              <p>Product Name</p>
              <p>Product ID</p>
              <p>Product category</p>
              <p>Received Date</p>
            </div>
            <div>
              <p>{product.productName}</p>
              <p>{product.productListId}</p>
              <p>{product.category}</p>
              <p>{product.receivedDate}</p>
            </div>
          </div>
          <h6 style={{ textAlign: "left", marginTop: "20px" }}>Supplier Details</h6>
          <div className=" view-product-details">
            <div>
              <p>Supplier name</p>
              <p>Supplier Contact no</p>

            </div>
            <div>
              <p>{product.supplierName}</p>
              <p>{product.supplierPhoneNo}</p>

            </div>
          </div>

        </div>
        <div className="mt-4 mx-5">
          <div >
            <img
              className="product-details-image"
              src={product.imageUrl ? `${PRODUCT_LIST_BASE_URL}${product.imageUrl}` : default_image}
              alt={product.productName}
            />
          </div>
          <div className=" view-product-details mt-5">
            <div>
              <p>{product.openingStock}</p>
              <p>{product.remainingStock}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="justify-content-start d-flex">
        <button className="add-product-button" onClick={handlebackbutton}> <IoIosArrowRoundBack style={{ color: "#fff", fontSize: "22px" }} /> &nbsp; Previous</button>
      </div>
      <EditProducts isModalVisible={isModalVisible} setIsModalVisible={setIsModalVisible} refreshProducts={fetchProduct} />
    </div>
  );
};

export default ViewProducts;
