import React, { useState, useEffect } from "react";
import { Modal, message, Rate } from "antd";
import profile from "../../Assets/images/singleuser.png";
import TextArea from "antd/es/input/TextArea";
import Instance from "../../AxiosConfig";

const ContactReply = ({ isModalVisible, setIsModalVisible, contactUsId, refreshContacts  }) => {
  const [loading, setLoading] = useState(false);
  const [replyContent, setReplyContent] = useState("");
  const [contactData, setContactData] = useState({});
  const [userImage, setUserImage] = useState(profile);

  useEffect(() => {
    if (isModalVisible) {
      console.log('contactUsId:', contactUsId);
      fetchContactData();
    }
  }, [isModalVisible]);

  const fetchContactData = async () => {
    try {
      const response = await Instance.get("/api/v1/contact-us/all", {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("adminAuthToken")}`,
        },
      });

      if (response.status === 200) {
        const contact = response.data.find(
          (contact) => contact.contactUsId === contactUsId
        );
        if (contact) {
          const formattedDate = new Intl.DateTimeFormat("en-US", {
            month: "long",
            day: "numeric",
            year: "numeric",
          }).format(new Date(contact.createdDate));

          setContactData({
            ...contact,
            createdDate: formattedDate
          });
          setUserImage(profile);
        } else {
          message.error("Contact  not found.");
        }
      } else {
        message.error("Failed to fetch contacts. Please try again.");
      }
    } catch (error) {
      message.error("An error occurred while fetching contacts. Please try again.");
    }
  };
  //   const fetchContactData = async () => {
  //     try {
  //       const url = `/api/v1/contact-us/all`;
  //       const response = await Instance.get(url, {
  //         headers: {
  //           Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
  //         },
  //       });
  //       if (response.status === 200) {
  //         console.log("Contact data fetched successfully:", response.data);
  //         const formattedContacts = response.data.map((contact) => {
  //           const date = new Date(contact.createdDate);
  //           const formattedDate = new Intl.DateTimeFormat("en-US", {
  //             month: "long",
  //             day: "numeric",
  //             year: "numeric",
  //           }).format(date);
  //           return {
  //             ...contact,
  //             formattedDate: `Posted on ${formattedDate}`,
  //           };
  //         });
  //         setContactData(formattedContacts);
  //       }
  //     } catch (error) {
  //       console.log("error:", error);
  //       message.error(error?.response?.data?.message || "An error occurred");
  //     }
  //   };

  const fetchNotificationCount = async () => {
    try {
      const response = await Instance.get("/api/v1/notification/", {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("adminAuthToken")}`,
          "Content-Type": "application/json",
        },
      });
      if (response.status === 200) {
        const orders = response.data.orders || [];
        const ratingReviews = response.data.ratingReviews || [];
        const contactUs = response.data.contactUs || [];
        const totalCount = orders.length + ratingReviews.length + contactUs.length;
        // setNotificationCount(totalCount);
      }
    } catch (error) {
      console.error("Error fetching notification count:", error);
    }
  };

  useEffect(() => {
    // fetchUserData();
    fetchNotificationCount();
  }, []);

  const handleReplyChange = (e) => {
    setReplyContent(e.target.value);
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const response = await Instance.post(
        `/api/v1/contact-us/reply/${contactUsId}`,
        { replyMessage: replyContent },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("adminAuthToken")}`,
            "Content-Type": "application/json",
          },
        }
      );
  
      if (response.status === 200) {
        message.success("Reply sent successfully!");
        setIsModalVisible(false);
        setReplyContent("");
        fetchNotificationCount();
        if (refreshContacts) {
          refreshContacts();
        }
      } else {
        message.error("Failed to send reply. Please try again.");
      }
    } catch (error) {
      message.error("An error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  


  return (
    <>
      <Modal
        title="Reply"
        visible={isModalVisible}
        onOk={() => setIsModalVisible(false)}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
      >
        <div>
          <div className="d-flex justify-content-between mt-4">
            <div className="d-flex gap-2 align-items-center">
              <div className="profile-email-icon">
                <img src={userImage} alt=".." />
              </div>
              <div>
                <p style={{ fontWeight: "bold", marginBottom: "0px" }}>
                  {contactData.name}
                </p>
              </div>
            </div>
            <p>{contactData.createdDate}</p>
          </div>
          <div className="d-flex gap-4 pt-3">
            <h6 className="reviews-how-to-apply">{contactData.subject}</h6>
            {/* <Rate allowHalf value={rating} /> */}
          </div>
          <p className="mb-0"><b>Service: </b>{contactData.services}</p>
          <p>{contactData.message}</p>
          <TextArea
            placeholder="Reply here..."
            className="form-control custom-placeholder mt-4"
            style={{ backgroundColor: "#f6f6f6", resize: "none", height: "150px" }}
            value={replyContent}
            onChange={handleReplyChange}
          />
          <button
            className="login-btn mt-4"
            id="login-button"
            onClick={handleSubmit}
            disabled={loading}
          >
            {loading ? "Sending..." : "Reply"}
          </button>
        </div>
      </Modal>
    </>
  );
};

export default ContactReply;
